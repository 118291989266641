.clsSidebar {
    width: 300px;
    background: #18191B;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    padding-left: 60px;
    padding-top: 30px;
    .classNavs {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 100px;
        button {
            margin-bottom: 60px;
            background-color: #18191B;
            color: #C3C7CC;
            display: flex;
            align-items: center;
            border: 0px;
            padding: 16px;
            span {
                font-weight: 600;
                font-size: 18px;
                margin-left: 10px;
            }
        }
        button:hover, button.active {
            background: #34363B;
            border-radius: 100px 0px 0px 100px;
            border-right: 5px solid #14B8A6;
            transition: 0.3s;
            svg {
                path {
                    fill: #14B8A6;
                }
            }
        }
    }
}

.flexClass {
    display: flex;
    .mainClass {
        display: flex;
        width: 100%;
        min-height: 100vh;
        background: #F6F6F7;
        .leftClass {
            display: flex;
            flex-direction: column;
            width: 60%;
            padding: 24px;
            .ant-input-affix-wrapper {
                background: #F6F6F7;
                border: 0.5px solid #C3C7CC;
                border-radius: 8px;
                padding: 12px 16px;
                outline: none;
                input {
                    background: #F6F6F7;
                }
                input::placeholder {
                    color: #4A4E57;
                }
            }
            .postDiv {
                background: #FFFFFF;
                border-radius: 8px;
                margin-top: 24px;
                padding: 16px;
                h3 {
                    font-weight: 600;
                    font-size: 18px;
                    margin-bottom: 20px;
                }
                .flexPst {
                    display: flex;
                    img {
                        height: 50px;
                        margin-right: 20px;
                    }
                    .questTab {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        textarea {
                            border: 1px solid #C3C7CC;
                            border-radius: 8px;
                            padding: 10px;
                            margin-bottom: 16px;
                            outline: none;
                        }
                        button {
                            width: fit-content;
                            padding: 10px 40px;
                            color: #FFFFFF;
                            background: #2563EB;
                            border-radius: 8px;
                            border: 1px solid #2563EB;
                            margin-top: 20px;
                        }
                        .ant-checkbox-wrapper {
                            span.ant-checkbox-inner {
                                color: #5E646E;
                                font-weight: 500;
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
            .quesCont {
                margin-top: 30px;
                background-color: #fff;
                height: stretch;
                border-radius: 8px;
                .emp {
                    div {
                        margin-top: 50px;
                        height: 300px;
                    }
                    p {
                        text-align: center;
                        color: #787F89;
                        font-weight: 600;
                        font-size: 20px;
                    }
                }
                h2 {
                    border-bottom: 1px solid #E1E3E6;
                    padding: 20px;
                    font-weight: 600;
                    margin-bottom: 0px;
                }
                .questionsWrapper {
                    background: #F6F6F7;
                    padding-top: 20px;
                    height: 800px;
                    overflow-y: auto;
                }
                .questionsWrapper::-webkit-scrollbar {
                    width: 5px;
                }
    
                /* Track */
                .questionsWrapper::-webkit-scrollbar-track {
                    background: #E1E3E6;
                }
    
                /* Handle */
                .questionsWrapper::-webkit-scrollbar-thumb {
                    background: #2563eb;
                    border-radius: 100px;
                }
            }
        }
        .rightClass {
            width: 40%;
            min-height: 100vh;
            background-color: #fff;
        }
    }
}

@media (max-width: 1250px){
    .flexClass {
        .mainClass {
            flex-direction: column;
            .leftClass, .rightClass, .rightClassRQ {
                width: 100%;
            }
        }
    }
}

@media (max-width:768px) {
    .flexClass {
        flex-direction: column;
        .mainClass {
            flex-direction: column;
            .leftClass, .rightClass {
                width: 100%;
            }
            .leftClass {
                padding: 15px;
                .postDiv {
                    .flexPst {
                        img {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}