.atHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    .leftTb {
        display: flex;
        align-items: center;
        h1 {
            margin-bottom: 0px;
            color: #18191B;
            font-weight: 600;
            margin-right: 15px;
        }
        button {
            background: #BFDBFE;
            border-radius: 4px;
            border: 1px solid #BFDBFE;
            width: 40px;
            height: 40px;
        }
    }
    .rghtTb {
        display: flex;
        align-items: center;
        .searchResults {
            height: 300px;
            width: 396px;
            background-color: #fff;
            position: absolute;
            top: 50px;
            right: 70px;
            overflow-y: auto;
            box-shadow: 0px 8px 24px rgba(94, 100, 110, 0.1);
            border-radius: 8px;
            padding: 14px;
            .lott {
                div {
                    height: 200px;
                }
                p {
                    text-align: center;
                    color: #18191B;
                    font-weight: 600;
                }
            }
        }
        .searchResults::-webkit-scrollbar {
            width: 5px;
        }
        
        /* Track */
        .searchResults::-webkit-scrollbar-track {
            background: #E1E3E6;
        }
        
        /* Handle */
        .searchResults::-webkit-scrollbar-thumb {
            background: #2563eb;
            border-radius: 100px;
        }
        img {
            height: 50px;
            width: 50px;
            border-radius: 100%;
            background-position: center;
            object-fit: cover;
        }
        button {
            background-color: transparent;
            border: 0px;
            margin: 0px 20px;
        }
        .ant-input-affix-wrapper {
            background: #F6F6F7;
            border: 0.5px solid #C3C7CC;
            border-radius: 8px;
            padding: 12px 16px;
            outline: none;
            width: 300px;
            input {
                background: #F6F6F7;
            }
            input::placeholder {
                color: #4A4E57;
            }
        }
        
    }
}

@media (max-width: 768px){
    .atHeader {
        flex-direction: column;
        align-items: flex-start;
        margin-top: 5px;
        .rghtTb {
            display: none;
        }
    }
}