
.leftClass {
    .quesIncl {
        margin-top: 20px;
        background: #FFFFFF;
        border-radius: 8px;
        padding: 18px;
        
    }
    .actQuestns {
        height: 90vh;
        margin-top: 10px;
        overflow-y: auto;
    }
    /* width */
    .actQuestns::-webkit-scrollbar {
        width: 3px;
    }

    /* Track */
    .actQuestns::-webkit-scrollbar-track {
        background: #E1E3E6;
    }

    /* Handle */
    .actQuestns::-webkit-scrollbar-thumb {
        background: #2563eb;
        border-radius: 100px;
    }
    .rkCont {
        margin-top: 30px;
    }
}
select {
    background: #F6F6F7;
    border: 1px solid #C3C7CC;
    border-radius: 8px;
    padding: 6px;
    width: 200px;
    margin-left: 8px;
}
.flxQIncl {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    h3 {
        font-weight: 600;
        margin-bottom: 0px;
    }
}
.emp {
    div {
        margin-top: 50px;
        height: 300px;
    }
    p {
        text-align: center;
        color: #787F89;
        font-weight: 600;
        font-size: 20px;
    }
}
.rightClassRQ {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 30px 20px 20px 0px;
    .ant-input-affix-wrapper {
        background: #F6F6F7;
        border: 0.5px solid #C3C7CC;
        border-radius: 8px;
        padding: 12px 16px;
        outline: none;
        width: 60%;
        input {
            background: #F6F6F7;
        }
        input::placeholder {
            color: #4A4E57;
        }
        margin-bottom: 30px;
    }
    .chartCont {
        background: #FFFFFF;
        border-radius: 8px;
        width: 100%;
        padding: 25px;
        margin-top: 30px;
        p {
            color: #18191B;
            font-weight: 500;
            font-size: 20px;
        }
    }
}
.quesContR {
    margin-top: 30px;
    .questionsWrapper {
        background: #F6F6F7;
        padding-top: 20px;
        height: 800px;
        overflow-y: auto;
    }
    .questionsWrapper::-webkit-scrollbar {
        width: 5px;
    }

    /* Track */
    .questionsWrapper::-webkit-scrollbar-track {
        background: #E1E3E6;
    }

    /* Handle */
    .questionsWrapper::-webkit-scrollbar-thumb {
        background: #2563eb;
        border-radius: 100px;
    }
}

@media (max-width: 768px) {
    .leftClass {
        .quesIncl {
            padding: 15px;
            .flxQIncl {
                h3 {
                    margin-bottom: 10px;
                }
                flex-direction: column;
                align-items: flex-start;
            }
        }
    }
    .rightClassRQ {
        width: 100%;
        padding: 15px;
        .ant-input-affix-wrapper, .flexSort {
            display: none;
        }
        margin-bottom: 100px;
    }

}