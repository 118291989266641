.hero {
    padding: 100px;
    margin-top: 100px;

    h1 {
        font-weight: 600;
        font-size: 75px;
        line-height: 100px;
        color: #1D4ED8;
        text-align: center;
        margin-bottom: 15px;
    }

    p {
        text-align: center;
        font-weight: 500;
        font-size: 20px;
        line-height: 32px;
        color: #5E646E;
    }

    .flxBtns {
        display: flex;
        justify-content: center;
        margin: 40px 0px;

        button {
            padding: 18px 35px;
            color: #fff;
            font-weight: 600;
            font-size: 16px;
            border-radius: 8px;
        }

        button.faci {
            background: #2563EB;
            border: 1px solid #2563EB;
            margin-right: 15px;
        }

        button.att {
            background: #14B8A6;
            border: 1px solid #14B8A6;
        }
    }

    img {
        height: 650px;
        display: block;
        margin: 30px auto;
        margin-top: 70px;
    }
}
@media (max-width: 768px) {
    .hero {
        padding: 40px 20px;

        h1 {
            font-size: 40px;
            line-height: 50px;
        }

        p {
            font-size: 16px;
            line-height: 25px;
        }

        .flxBtns {
            flex-direction: column;

            button {
                padding: 18px 50px;
                width: 100%;
            }

            button.faci {
                margin-right: 0px;
                margin-bottom: 15px;
            }
        }

        img {
            height: 200px;
        }
    }

}