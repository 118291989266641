.mainAbt {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.flxAbt {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 80px;
    margin-bottom: 156px;
    overflow: hidden;

    p {
        font-weight: 400;
        font-size: 34px;
        line-height: 56px;
        color: #5E646E;
        width: 80%;
    }

    .leftAbt,
    .rightAbt {
        width: 50%;
        img {
                height: 500px;
            }
    }

}
@media (max-width: 768px) {
    .flxAbt {
        flex-direction: column;
        padding: 20px;
            margin-bottom: 0px;

        .leftAbt,
        .rightAbt {
            width: 100%;
            img {
                    height: 270px;
                    display: block;
                    margin: 0px auto 20px auto;
                }
        }

        p {
            width: 100%;
            font-size: 16px;
                line-height: 24px;
        }

        
    }

    .tw {
        flex-direction: column-reverse;
    }
}