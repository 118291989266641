.ctcCont {
    background: #E1E3E6;
    padding: 60px;

    .flxCtc {
        display: flex;
        justify-content: center;

        .lftCtc {
            margin-right: 50px;

            h2 {
                font-weight: 600;
                font-size: 54px;
                color: #18191B;
                margin-bottom: 15px;
                line-height: 72px;
            }

            p {
                font-size: 20px;
                line-height: 32px;
            }

        }

        .rghtCtc {
            width: 35%;

            form {
                display: flex;
                flex-direction: column;

                input,
                textarea {
                    border-radius: 8px;
                    margin-bottom: 16px;
                    border: 0.5px solid #C3C7CC;
                    padding: 15px;
                    outline: none;
                    background: #fafafa;
                }

                button {
                    padding: 16px 40px;
                    color: #fff;
                    font-weight: 600;
                    font-size: 16px;
                    border-radius: 8px;
                    background: #2563EB;
                    border: 1px solid #2563EB;
                    align-self: flex-start;
                }
            }
        }
    }
}
@media (max-width: 768px) {
    .ctcCont {
        padding: 50px 20px;

        .flxCtc {
            flex-direction: column;

            .lftCtc {
                margin-right: 0px;

                h2 {
                    font-size: 32px;
                    line-height: 48px;
                }

                p {
                    font-size: 16px;
                        line-height: 24px;
                }
            }

            .rghtCtc {
                width: 100%;

                form {
                    button {
                        width: 100%;
                    }
                }
            }
        }

    }
}