.searchCard {
    margin-bottom: 16px;
    border: 1px solid #E1E3E6;
    border-radius: 8px;
    padding: 16px;
    position: relative;
    cursor: pointer;
    .bdBlue {
        background: #2563EB;
        border-radius: 100px;
        width: 4px;
        height: 40px;
        position: absolute;
        left: -1px;
        top: 25px;
    }
    p.title {
        font-weight: 500;
        font-size: 16px;
        color: #3E4047;
    }
    .flexSCard {
        display: flex;
        .date, .time {
            display: flex;
            align-items: center;
            margin-right: 25px;
            color: #787F89;
            font-weight: 600;
        }
    }
}