.wHero {
    height: 80vh;
    background-image: url("../../../images/abg.png");
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 100px;

    .btnWl {
        display: flex;
        flex-direction: column;
        align-items: center;

        button {
            padding: 18px 50px;
            color: #fff;
            font-weight: 600;
            font-size: 16px;
            border-radius: 8px;
        }

        button.faci {
            background: #2563EB;
            border: 1px solid #2563EB;
            margin-bottom: 25px;
        }

        button.att {
            background: #14B8A6;
            border: 1px solid #14B8A6;
        }
    }

h1 {
        font-style: normal;
        font-weight: 600;
        font-size: 88px;
        line-height: 120px;
        color: #3E4047;
    }
}
@media (max-width: 768px) {
    .wHero {
        height: 50vh;
            padding: 0px 20px;
            margin-top: 80px;
        
            .btnWl {
                align-items: stretch;
                width: 100%;
        
                button {
                    width: 100%;
                }
            }
        h1 {
            font-size: 36px;
            color: #1D4ED8;
                line-height: 80px;
        }
    }
}