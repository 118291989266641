footer {
    background: #2563EB;
    color: #fff;
    padding: 100px 60px 20px 60px;

    p.main {
        font-weight: 300;
        font-size: 50px;
        line-height: 68px;
        text-align: center;
        width: 100%;

        span.spec {
            font-weight: 700;
            border-bottom: 6px solid #FBBF24;
                line-height: 0.7;
            display: inline-block;
        }

    }

    button {
        display: block;
        margin: 0px auto;
        padding: 15px 40px;
        color: #18191B;
        font-weight: 700;
        font-size: 16px;
        border-radius: 8px;
        background: #99F6E4;
        border: 1px solid #99F6E4;
    }

    .lastLine {
        display: flex;
        justify-content: space-between;
        margin-top: 100px;

        .rgt {
            display: flex;

            p {
                margin-left: 80px;
            }
        }
    }
}
@media (max-width: 768px) {
    footer {
        padding: 40px 20px;

        p.main {
            font-size: 25px;
            line-height: 45px;
            span.spec {
                    border-bottom: 0px;
                }
        }

        .lastLine {
            flex-direction: column;
            align-items: center;
            margin-top: 50px;

            .rgt {
                width: 100%;
                    justify-content: space-between;
                    margin-top: 20px;

                p {
                    margin-left: 0px;
                    text-align: center;
                }
            }
        }
    }
}