.dashCont {
  display: flex;
  background: #f6f6f7;

  .flexMain {
    display: flex;
    padding: 25px;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;

    .editCont {
      width: 100%;
      .goback {
        display: flex;
        align-items: center;
        cursor: pointer;
        width: fit-content;

        h3 {
          margin-bottom: 0px;
          color: #34363b;
          font-weight: 600;
          font-size: 20px;
          line-height: 30px;
          margin-left: 5px;
        }
      }

      .fwdth {
        display: flex;
        justify-content: center;

        .edWht {
          background: #ffffff;
          border-radius: 8px;
          width: 40%;
          padding: 25px 20px 80px 20px;
          margin-top: 30px;

          .flxImg {
            display: flex;
            align-items: center;
            margin: 30px 0px;
            flex-wrap: wrap;

            img.profilePic {
              margin-right: 20px;
              height: 150px;
              width: 150px;
              border-radius: 100%;
              background-position: center;
              object-fit: cover;
            }

            button.upload {
              border: 0.5px solid #c3c7cca2;
              border-radius: 8px;
              padding: 10px 15px;
              height: auto;

              span {
                color: #5e646e;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
              }
            }
          }

          .formField {
            display: flex;
            flex-direction: column;
            margin-top: 20px;

            label {
              color: #787f89;
              font-weight: 700;
              font-size: 12px;
              line-height: 15px;
              margin: 0px 0px 5px 5px;
            }

            select,
            input {
              background: #ffffff;
              padding: 12px;
              border: 1px solid #4a4e57;
              border-radius: 8px;
              color: #5e646e;
              outline: none;
            }
          }

          button.save {
            background: #2563eb;
            border-radius: 8px;
            padding: 16px 40px;
            border: 1px solid #2563eb;
            color: #ffffff;
            font-weight: 500;
            margin-top: 30px;
          }

          .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
            width: 100%;
            justify-content: space-around;
          }

          .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
          .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn:hover {
            color: #2563eb;
          }

          .ant-tabs-tab-btn:focus,
          .ant-tabs-tab-btn:hover,
          .ant-tabs-tab:hover {
            color: #2563eb;
          }

          .ant-tabs-ink-bar {
            background: #2563eb;
            border-radius: 100px;
            height: 3px;
          }

          .ant-tabs-tab {
            padding: 0px 0px 10px 0px;
          }
        }
      }
    }

    .inviteCont {
      width: 100%;
      .tableCont {
        button.deactivate {
          background: #DC2626;
          border-radius: 4px;
          padding: 8px 16px;
          color: #fff;
          border: 0px;
        }
        button.deactivate:disabled {
          background: #C3C7CC;
          cursor: not-allowed;
        }
        span.numAtt {
          color: #14B8A6;
          font-weight: 600;
        }
      }

      h2 {
        font-weight: 600;
        font-size: 24px;
        line-height: 30px;
        color: #34363b;
        margin-bottom: 40px;
      }

      .ivWhtCont {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #ffffff;
        border-radius: 8px;
        padding-bottom: 100px;
        height: 80vh;

        .empty {
            text-align: center;
          div {
            height: 300px;
          }

          p {
            color: #787f89;
            font-weight: 600;
            font-size: 20px;
            line-height: 30px;
            text-align: center;
          }

          a {
            color: #2563eb;
            text-decoration: underline;
          }
        }
      }
    }

    .lectureCont {
      width: 100%;

      .topLec {
        display: flex;
        align-items: center;

        h2 {
          margin-bottom: 0px;
          font-weight: 600;
          font-size: 24px;
          line-height: 30px;
          color: #34363b;
        }

        button {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 5px 6px 5px 1px;
          background-color: #ffffff;
          color: #2563eb;
          border: 1px solid #2563eb;
          border-radius: 8px;
          margin-left: 15px;

          span {
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
          }
        }
      }

      .tableCont {
        background-color: #ffffff;
        margin-top: 45px;
        padding-bottom: 50px;

        .padSk {
          padding: 30px;
        }

        .ant-table-pagination.ant-pagination {
          padding-right: 30px;
        }

        button.vDeets {
          color: #fff;
          padding: 7px 16px;
          background: #2563eb;
          border-radius: 4px;
          border: 1px solid #2563eb;
          outline: none;
        }

        span.draft {
          color: #4a4e57;
          background: #e1e3e6;
          border-radius: 100px;
          padding: 4px 16px;
          font-weight: 600;
        }

        span.published {
          color: #ffffff;
          background: #0d9488;
          border-radius: 100px;
          padding: 4px 16px;
          font-weight: 600;
        }

        span.unpublished {
          color: #ffffff;
          background: #ef4444;
          border-radius: 100px;
          padding: 4px 16px;
          font-weight: 600;
        }

        .ant-table-thead > tr > th {
          background-color: #fff !important;
        }

        th.ant-table-cell::before {
          width: 0px !important;
        }

        
          div.emp {
            display: flex;
            flex-direction: column;
            align-items: center;
            div {
              height: 300px;
            }

            p {
              font-weight: 600;
              font-size: 20px;
              line-height: 30px;

              color: #787f89;
            }

            button {
              background: #2563eb;
              border-radius: 8px;
              padding: 16px 40px;
              border: 1px solid #2563eb;
              color: #ffffff;
              font-weight: 500;
              margin-top: 10px;
            }
          }
        
      }
    }

    .fstMain {
      background: #ffffff;
      border-radius: 8px;
      padding: 24px;
      width: 49%;

      .pdTp,
      .lastMn {
        padding-top: 30px;
      }

      .lastMn {
        label {
          color: #787f89;
          font-weight: 700;
          font-size: 12px;
          line-height: 15px;
          margin: 0px 0px 5px 5px;
        }

        .ant-input-affix-wrapper {
          background: #f6f6f7;
          padding: 12px;
          border: 1px solid #c3c7cc;
          border-radius: 8px;
          color: #5e646e;

          input {
            background: #f6f6f7;
          }
        }

        button {
          background: #2563eb;
          border-radius: 8px;
          padding: 16px 40px;
          border: 1px solid #2563eb;
          color: #ffffff;
          font-weight: 500;
          margin-top: 30px;
        }
      }

      .topFstMain {
        border-bottom: 0.5px solid #c3c7cca2;
        padding-bottom: 50px;
        img.profilePic {
          height: 150px;
            width: 150px;
            border-radius: 100%;
            background-position: center;
            object-fit: cover;
        }

        h1 {
          font-weight: 600;
          font-size: 36px;
          line-height: 45px;
          color: #3e4047;
          margin-top: 20px;
        }

        p {
          color: #9da3ab;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
        }

        button.edit {
          display: flex;
          align-items: center;
          background: #eff6ff;
          border-radius: 8px;
          border: 1px solid #eff6ff;
          padding: 8px 12px;
          margin-top: 30px;

          span {
            color: #5e646e;
            font-weight: 600;
            font-size: 14px;
            margin-left: 5px;
          }
        }

        .formField {
          display: flex;
          flex-direction: column;
          margin-top: 20px;

          label {
            color: #787f89;
            font-weight: 700;
            font-size: 12px;
            line-height: 15px;
            margin: 0px 0px 5px 5px;
          }

          select {
            background: #f6f6f7;
            padding: 12px;
            border: 1px solid #c3c7cc;
            border-radius: 8px;
            color: #5e646e;
            width: 100%;
          }
        }

        .icons {
          display: flex;
          justify-content: space-between;
          img {
            height: 150px;
            width: 150px;
            border-radius: 100%;
            background-position: center;
            object-fit: cover;
          }

          .nvIcons {
            display: flex;
            align-items: flex-start;

            button {
              margin-left: 27px;
              display: flex;
              align-items: center;
              padding: 6px;
              background-color: #fff;
              border: none;
              border-radius: 50%;
            }

            button:hover {
              background-color: #c3c7cc7c;
              transition: 0.2s;
            }
          }
        }
      }

      .btFstMain {
        .padTp {
          padding-top: 40px;
        }

        .newUser {
          p {
            color: #9da3ab;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            margin: 40px 0px 70px 0px;
          }

          button {
            background: #2563eb;
            border-radius: 8px;
            padding: 16px 40px;
            border: 1px solid #2563eb;
            color: #ffffff;
            font-weight: 500;
          }
        }

        .activeUser {
          .topActUser {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 30px 0px;

            p {
              width: 80%;
              margin-bottom: 0px;
              span.drft {
                color: #D97706;
                font-weight: 600;
              }
              span.actv {
                color: #2563EB;
                font-weight: 600;
              }
              span.pubz{
                color: #14B8A6;
                font-weight: 600;
              }
              span.unpubz {
                color: #DC2626;
                font-weight: 600;
              }
            }

            button {
              display: flex;
              justify-content: center;
              align-items: center;
              background: #dbeafe;
              border: 1px solid #dbeafe;
              padding: 7px;
              border-radius: 8px;
            }
          }

          .lecturesConta {
            max-height: 50vh;
            overflow-y: auto;
          }

          /* width */
          .lecturesConta::-webkit-scrollbar {
            width: 4px;
          }

          /* Track */
          .lecturesConta::-webkit-scrollbar-track {
            background: #f6f6f7;
          }

          /* Handle */
          .lecturesConta::-webkit-scrollbar-thumb {
            background: #2563eb;
            border-radius: 100px;
          }

          button.addNew {
            height: 120px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #f6f6f7;
            border-radius: 8px;
            color: #787f89;
            border: 1px solid #f6f6f7;
            margin-top: 30px;
          }
        }
      }
    }

    .scdMain {
      width: 49%;
      display: flex;
      flex-direction: column;

      button.logout {
        padding: 15px;
        color: #2563eb;
        font-weight: 700;
        font-size: 16px;
        background-color: transparent;
        border: 2px solid #2563eb;
        border-radius: 8px;
        margin-top: 20px;
      }

      button.deactivate {
        padding: 15px;
        color: #dc2626;
        font-weight: 700;
        font-size: 16px;
        background-color: transparent;
        border: 2px solid #dc2626;
        border-radius: 8px;
        margin-top: 20px;
      }

      .ant-switch-checked {
        background: #1d4ed8;
      }

      .toggCont {
        display: flex;
        justify-content: space-between;

        span {
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: #4a4e57;
        }
      }

      .mgtP {
        margin-top: 40px;
      }

      .notifcation {
        background: #ffffff;
        border-radius: 8px;
        padding: 20px 24px 40px 24px;

        p {
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: #4a4e57;
        }

        h2 {
          font-weight: 600;
          font-size: 24px;
          line-height: 30px;
          margin-bottom: 25px;
        }

        .tpNtf {
          padding-bottom: 20px;
          border-bottom: 0.5px solid #c3c7cca2;
        }

        .btNtf {
          padding-top: 30px;

          h3 {
            margin-bottom: 0px;
            font-weight: 600;
            font-size: 20px;
            line-height: 25px;
          }
        }
      }

      .tpScMn {
        padding: 20px;

        h2 {
          color: #34363b;
          font-weight: 600;
          font-size: 24px;
          line-height: 30px;
        }

        .lectureBox {
          border: 0.5px solid #c3c7cca2;
          border-radius: 8px;
          background: #ffffff;
          padding: 50px 25px;
          height: 400px;
          overflow-y: auto;
          .liveNo {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 250px;
          }
          p {
            color: #787f89;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            margin-bottom: 0px;
            text-align: center;
          }
          .publishedCont {
            width: 100%;
            .flxTopCd {
              .nmTitle {
                p {
                  width: inherit;
                }
              }
            }
            .flxCd {
              p {
                width: inherit;
                font-weight: 500;
              }
            }
          }

          h3 {
            color: #787f89;
            font-weight: 600;
            font-size: 24px;
            line-height: 30px;
            text-align: center;
          }

         
        }
        .lectureBox::-webkit-scrollbar {
          width: 4px;
        }

        /* Track */
        .lectureBox::-webkit-scrollbar-track {
          background: #f6f6f7;
        }

        /* Handle */
        .lectureBox::-webkit-scrollbar-thumb {
          background: #2563eb;
          border-radius: 100px;
        }
      }

      .btScMn {
        background: #ffffff;
        border-radius: 8px;
        height: stretch;
        height: -webkit-fill-available;
        margin-top: 20px;
        display: flex;
        flex-direction: column;

        h2 {
          border-bottom: 0.5px solid #c3c7cca2;
          padding: 20px;
          font-weight: 600;
          font-size: 24px;
          line-height: 30px;
          color: #34363b;
          margin-bottom: 0px;
        }

        .lott {
          height: inherit;
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          padding: 10px 0pc 40px 0px;

          div {
            height: 250px;
          }

          p {
            color: #787f89;
            font-weight: 600;
            font-size: 24px;
            line-height: 30px;
            text-align: center;
          }
        }
      }
    }
  }
}

@media (max-width: 1250px) {
  .dashCont {
    .flexMain {
      flex-direction: column;
      .fstMain, .scdMain {
        width: 100%;
      }
      .lectureDtCont {
        .flxLcd {
          flex-direction: column;
        }
      }
        .lectureCont {
          .tableCont {
              .ant-table-pagination.ant-pagination {
                padding-right: 10px;
              }
              .ant-table-content {
                overflow-x: auto;
                table {
                  width: 150%;
                }
              }
          }
      }
    }
  }
}

@media (max-width: 768px) {
    .sidebar {
        display: none;
    }
    .dashCont {
        flex-direction: column;
        .flexMain {
            padding: 15px;
            flex-direction: column;
            min-height: 100vh;
            .editCont {
                .fwdth {
                    .edWht {
                        width: 100%;
                    }
                }
            }
            .inviteCont {
                .ivWhtCont {
                    .empty {
                        div {
                            height: 200px;
                        }
                    }
                }
            }
            .lectureCont {
                .tableCont {
                    div.emp {
                        div {
                            height: 200px;
                        }
                    }
                }
            }
            .fstMain {
                width: 100%;
                padding: 15px;
                .topFstMain {
                    padding-bottom: 0px;
                    border-bottom: 0px;
                    .icons {
                        display: none;
                    }
                    h1 {
                        font-size: 28px;
                        margin-top: 0px;
                    }
                    .formField {
                      select {
                        width: 100%;
                        margin-left: 0px;
                      }
                    }
                }
                .btFstMain {
                    .activeUser {
                        button.addNew {
                            margin-top: 10px;
                        }
                    }
                }
                .lastMn {
                    button {
                        width: 100%;
                    }
                }
            }
            .scdMain {
                width: 100%;
                margin-top: 30px;
                .toggCont {
                    align-items: center;
                    span {
                        font-size: 14px;
                        width: 80%;
                    }
                }
                .tpScMn {
                    padding: 0px;
                    h2 {
                        font-size: 20px;
                    }
                    .lectureBox {
                        h3 {
                            font-size: 20px;
                        }
                        p {
                            font-size: 15px;
                            width: 80%;
                        }
                    }
                }
                
                .btScMn {
                  
                  h2 {
                      font-size: 20px;
                  }
                  .lott {
                    p {
                        font-size: 18px;
                    }
                    div {
                        height: 200px;
                    }
                  }
                  
                }
                .notifcation {
                    h2 {
                        font-size: 20px;
                    }
                    .btNtf {
                        h3 {
                            font-size: 16px;
                        }
                    }
                }
            }
            .lectureCont {
                .tableCont {
                    .ant-table-pagination.ant-pagination {
                        padding-right: 10px;
                    }
                    .ant-table-content {
                        overflow-x: auto;
                        table {
                            width: 320%;
                        }
                    }
                }
            }
        }
    }
}
.rnkDash {
  padding: 25px;
}