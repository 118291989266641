.onbdMain {
    background: #EFF6FF;
    min-height: 100vh;

    .onbdCont {
        position: relative;
        z-index: 1;
        top: 100px;
            background: #EFF6FF;

        img {
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: -1;
            height: 700px;
        }

        .whtCont {
            background: #FFFFFF;
            border-radius: 24px 24px 0px 0px;
            width: 42%;
            display: block;
            margin: 30px auto;
            padding: 50px;
            z-index: 1;
            min-height: 80vh;

            .tpWht {
                display: flex;
                justify-content: space-between;
                align-items: baseline;

                h4 {
                    font-weight: 600;
                    font-size: 36px;
                    line-height: 48px;
                }

                p {
                    text-decoration: underline;
                    font-weight: 600;
                    color: #2563EB;
                    font-size: 16px;
                }

            }

            h4 {
                font-weight: 600;
                font-size: 36px;
                line-height: 48px;
                text-align: center;
            }

            h3,
            p.ver {
                text-align: center;
            }

            h3 {
                color: #34363B;
                font-weight: 600;
                font-size: 16px;
            }

            p.ver {
                font-weight: 400;
                font-size: 12px;
                color: #34363B;
            }

            .lott {
                div {
                    height: 350px;
                }
            }

            form {
                display: flex;
                flex-direction: column;
                margin-top: 20px;

                .otpCont {
                    display: flex;
                    justify-content: center;
                    margin: 20px 0px 35px 0px;

                    input {
                        height: 50px;
                        width: 50px !important;
                        margin-right: 10px;
                        border: 1px solid #C3C7CC;
                        border-radius: 8px;
                        background: #F6F6F7;
                    }

                    input:focus {
                        border: 1px solid #60A5FA;
                        outline: none;
                    }
                }
.passwordCheck {
    display: flex;
    margin-bottom: 20px;
    flex-wrap: wrap;
    transition: 0.3s;

    p {
        display: flex;
        align-items: center;
        color: #787F89;
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;
        background: #E1E3E6;
        padding: 2px 8px;
        margin-right: 10px;
        border-radius: 100px;
        transition: 0.3s;
    }

    p.pass {
        background: #99F6E4;
        color: #0F766E;
        transition: 0.3s;
    }
}

                .ant-input-affix-wrapper,
                input#email,
                input#lastName,
                input#firstName {
                    padding: 16px;
                    background: #F6F6F7;
                    border: 1px solid #C3C7CC;
                    border-radius: 8px;
                }

                input#email,
                .ant-input-affix-wrapper,
                input#lastName,
                input#firstName {
                    margin-bottom: 20px;
                }

                .ant-input-affix-wrapper>input.ant-input {
                    background-color: #F6F6F7;
                }

                input::placeholder,
                .ant-input::placeholder {
                    color: #9DA3AB;
                }

                button {
                    background: #2563EB;
                    border-radius: 8px;
                    padding: 16px;
                    color: #FFFFFF;
                    border: 1px solid #2563EB;
                    font-weight: 600;
                    margin-top: 20px;
                }

                input#email:focus,
                input#lastName:focus,
                input#firstName:focus {
                    border: 1px solid #2563EB;
                        outline: none;
                }

                p.ctnc {
                    color: #787F89;
                    font-weight: 400;
                    font-size: 16px;
                    text-align: center;

                    span {
                        font-weight: 700;
                        border-bottom: 1px solid #787f898f;
                    }
                }
            }

            p.fget {
                text-align: center;
                font-weight: 500;
                font-size: 16px;
                margin-top: 40px;
                color: #3E4047;
            }

            p.renD {
                text-align: center;
                margin: 40px 0px;
                font-size: 18px;
                line-height: 23px;
                color: #4A4E57;
            }

            .tnc {
                display: flex;
                margin-top: 200px;

                span {
                    margin: 0px 10px;
                }

                p {
                    color: #787F89;
                    font-size: 16px;
                    border-bottom: 1px solid #787F89;
                }
            }
        }
    }

}
@media (max-width: 768px) {
    .onbdMain {
        background-color: #FFF;
        .onbdCont {
            padding: 0px 20px;
                top: 100px;
                background: #fff;
            img {
                display: none;
            }


            .whtCont {
                width: 100%;
                    padding: 0px;
                    border-radius: 0;
                    background-color: transparent;

                h4 {
                    font-size: 25px;
                }

                .tpWht {
                    h4 {
                        font-size: 34px;
                            line-height: 48px;
                    }

                    p {
                        font-size: 14px;
                    }
                }

                form {
                    p.ctnc {
                        font-size: 14px;
                    }
                }

                p.renD {
                    font-size: 14px;
                }

                .tnc {
                    justify-content: center;
                        align-items: center;
                    margin-top: 60px;

                    span {
                        margin: 0px 20px;
                    }

                    p {
                        margin-bottom: 0px;
                        font-size: 14px;
                    }
                }
            }
        }
    }
}