.sidebar {
    background-color: #18191B;
    width: 160px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 0px;
    min-height: 100vh;

    .logo {
        margin-bottom: 100px;
    }

    .nvLinksSB {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #9DA3AB;
        justify-content: center;
        width: 85px;
        height: 85px;
        border-radius: 8px;
        margin-bottom: 30px;
        cursor: pointer;

        p {
            margin-bottom: 0px;
        }
    }

    .nvLinksSB:hover,
    .nvActive {
        color: #fff;
        background: #3E4047;
        transition: 0.3s;

        svg {
            path {
                stroke: #14B8A6;
            }
        }
    }
}

.mobileBar {
    display: none;
}

@media (max-width: 768px){
    .sidebar {
        display: none;
    }
    .mobileBar {
        display: block;
        background: #18191B;
        padding: 15px;
        .topbar {
            display: flex;
            justify-content: space-between;
            align-items: center;
            button {
                background-color: transparent;
                border: 0px;
                outline: none;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .brLft, .brRght {
                display: flex;
                align-items: center;
                img {
                    height: 40px;
                    margin-left: 5px;
                }
                img.prpic {
                    margin-left: 20px;
                    height: 40px;
                    width: 40px;
                    border-radius: 100%;
                } 
            }

        }
        
        
    }
    .navBarMb {
        background-color: #fff;
        height: inherit;
        padding: 15px;
        button {
            background-color: transparent;
            border: 0px;
            outline: none;
        }
        .flxedn {
            display: flex;
            justify-content: flex-end;
            button.close {
                svg {
                    height: 30px;
                }
            }
        }
        .navs {
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding-left: 30px;
            button {
                margin-top: 45px;
                display: flex;
                align-items: center;
                span {
                    margin-left: 5px;
                    color: #787F89;
                    font-weight: 600;
                }
            }
            button.active {
                span {
                    color: #18191B;
                    font-weight: 700;
                }
                svg {
                    path {
                        stroke: #14B8A6;
                    }
                }
            }
        }
        
    }
}