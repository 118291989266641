.ant-modal-content {
    border-radius: 8px;

    .modCont {
        background-color: #fff;
        border-radius: 8px;
        padding: 10px;
        .succs {
                h2 {
                    text-align: center;
                    margin-bottom: 0px;
                    color: #34363B;
                    font-weight: 600;
                    font-size: 25px;
                    line-height: 48px;
                }
        
                .lott {
                    div {
                        height: 300px;
                    }
                }
        
                p {
                    text-align: center;
                    color: #34363B;
                    font-size: 18px;
                }
        
                button {
                    padding: 15px;
                    border-radius: 8px;
                    background: #2563EB;
                    color: #fff;
                    border: 2px solid #2563EB;
                    margin-top: 15px;
                    width: 100%;
                }
            }

        .tpMod {
            display: flex;
            align-items: center;
            justify-content: space-between;

            h2 {
                margin-bottom: 0px;
                color: #34363B;
                font-weight: 600;
                font-size: 25px;
                line-height: 48px;
            }

            button {
                color: #14B8A6;
                border: 2px solid #14B8A6;
                padding: 9px 20px;
                background: #FFFFFF;
                font-weight: 700;
                border-radius: 8px;
                cursor: pointer;
            }
        }

        form {
            display: flex;
            flex-direction: column;
            margin-top: 40px;

            input#title,
            input#keywords,
            textarea {
                padding: 18px 15px;
                margin-bottom: 15px;
                background: #F6F6F7;
                border: 1px solid #C3C7CC;
                border-radius: 8px;
                outline: 0;
            }

            input::placeholder,
            textarea::placeholder {
                color: #9DA3AB;
                font-weight: 400;
                font-size: 15px;
            }


            .flx {
                display: flex;
                justify-content: space-between;
                margin-bottom: 15px;
                input#keywords {
                    width: 100%;
                    margin-bottom: 0px;
                }
                button.addKey {
                    margin-top: 0px;
                    margin-left: 10px;
                    padding: 15px 25px;
                    font-weight: 500;
                }
                button.publish, button.cancel {
                    width: 49%;
                }
                button.cancel {
                    background: #acafb2e8;
                    border: 1px solid #acafb2e8;
                }
                button.publish:disabled {
                    background: #BFDBFE;
                    font-weight: 600;
                    color: #60A5FA;
                    border: 1px solid #BFDBFE;
                    cursor: not-allowed;
                }

                .ant-picker {
                    width: 49%;
                    padding: 18px 15px;
                    margin-bottom: 15px;
                    background: #F6F6F7;
                    border: 1px solid #C3C7CC;
                    border-radius: 8px;
                    outline: 0;

                    .ant-picker-input {
                        display: flex;
                        align-items: center;
                        flex-direction: row-reverse;

                        span {
                            margin: 0px 4px 0px 0px;

                            svg {
                                fill: #9DA3AB;
                                font-weight: bolder;
                            }
                        }
                    }
                }
            }

            p {
                color: #14B8A6;
                font-weight: 500;
            }

            p.lecErr {
                color: #DC2626;
                background: #FEE2E2;
                border-radius: 8px;
                padding: 8px 12px;
                margin-bottom: 25px;
                font-weight: 500;
            }

            button {
                padding: 15px;
                border-radius: 8px;
                background: #2563EB;
                color: #fff;
                border: 2px solid #2563EB;
                margin-top: 15px;
            }

            .tagWrp {
                display: flex;
                align-items: center;
                background: #99F6E4;
                border-radius: 100px;
                margin-right: 8px;
                margin-bottom: 8px;
                padding: 3px 10px 3px 5px;
                flex-wrap: wrap;

                svg {
                    cursor: pointer;
                }

                span {
                    color: #0F766E;
                    font-weight: 600;
                    font-size: 13px;
                    margin-left: 3px;
                    word-break: break-all;
                }
            }

            .kwdCont {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                margin-bottom: 10px;
            }
        }
    }
}
.ant-picker-footer {
    display: none !important;
}

@media (max-width: 768px) {
    .ant-modal-body {
        padding: 15px;
        .modCont {
            padding: 0px;
            form {
                .flx {
                    flex-direction: column;
                    .ant-picker {
                        width: 100%;
                    }
                    button.addKey {
                        margin-left: 0px;
                        margin-top: 7px;
                    }
                    button.publish, button.cancel {
                        width: 100%;
                    }
                }
            }
        }
    }
}