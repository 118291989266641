.classLDeets {
    background-color: #fff;
    padding-top: 20px;
    .topDetails {
        display: flex;
        flex-direction: column;
        align-items: center;
        border-bottom: 1px solid #E1E3E6;
        padding-bottom: 45px;
        img {
            height: 120px;
        }
        h3 {
            color: #3E4047;
            margin: 8px 0px;
            font-weight: 600;
            font-size: 18px;
        }
        p.user_type {
            color: #787F89;
            font-weight: 700;
        }
        button {
            border: 3px solid #2563EB;
            border-radius: 8px;
            background: #FFFFFF;
            color: #2563EB;
            padding: 12px 26px;
            font-weight: 700;
            margin-top: 15px;
        }
    }
    .remDetails {
        padding: 20px;
        .fstMain {
            h1 {
                font-weight: 600;
                font-size: 21px;
                color: #34363B;
                margin-bottom: 30px;
            }
            label {
                color: #787F89;
                font-weight: 700;
                font-size: 12px;
            }
            h3 {
                color: #3E4047;
                font-weight: 600;
                font-size: 20px;
            }
            .flCol {
                display: flex;
                flex-direction: column;
                margin: 30px 0px;
                textarea {
                    border: 1px solid #C3C7CC;
                    border-radius: 8px;
                    outline: none;
                    padding: 8px 10px;
                }
            }
            .TIMDcNT {
                display: flex;
                margin-top: 5px;
                .date, .time {
                    margin-right: 30px;
                    display: flex;
                    align-items: center;
                    span {
                        color: #1D4ED8;
                        font-weight: 500;
                        font-size: 16px;
                        margin-left: 3px;
                    }
                }
            }
            .mgTp {
                margin: 30px 0px;
                .keywCont {
                    margin: 3px 0px;
                    span.keyW {
                        background: #99F6E4;
                        border-radius: 100px;
                        font-weight: 600;
                        font-size: 12px;
                        color: #0F766E;
                        padding: 5px 15px;
                        margin-right: 6px;
                    }
                }
            }
            p.faci {
                color: #3E4047;
                font-weight: 600;
                font-size: 18px;
            }
            button.join {
                width: 100%;
                padding: 15px;
                color: #2563EB;
                font-weight: 700;
                background: #fff;
                border: 2px solid #2563EB;
                border-radius: 8px;
                margin-top: 30px;
            }
            button.leave {
                width: 100%;
                padding: 15px;
                color: #DC2626;
                font-weight: 700;
                background: #fff;
                border: 2px solid #DC2626;
                border-radius: 8px;
                margin-top: 30px;
            }
        }
    }
}