.draftCont {
    background-color: #fff;
    border: 1px solid #C3C7CC;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;

    .nmTitle {
        h2 {
            color: #5E646E;
            font-weight: 600;
        }

        p {
            color: #9DA3AB;
        }
    }

    .flxCd {
        p {
            color: #787F89;
            font-weight: 500;
        }

        button {
            color: #3E4047;
            background: #E1E3E6;
            border: 1px solid #E1E3E6;
        }
    }
}

.unpublishedCont {
    background: #FFFBEB;
    border-radius: 8px;
    padding: 20px;
    border: 1px solid #FFFBEB;
    margin-bottom: 20px;

    .nmTitle {
        h2 {
            color: #5E646E;
            font-weight: 600;
        }

        p {
            color: #9DA3AB;
        }
    }

    .flxCd {
        p {
            color: #787F89;
            font-weight: 500;
        }

        button {
            color: #3E4047;
            background: #FDE68A;
            border: 1px solid #FDE68A;
        }
    }
}

.activeLecCont {
    border-radius: 8px;
    background: #2563EB;
    padding: 20px;
    border: 1px solid #2563EB;
    margin-bottom: 20px;

    .nmTitle {
        h2 {
            color: #fff;
            font-weight: 600;
        }

        p {
            color: #E1E3E6;
        }
    }

    .flxCd {
        p {
            color: #fff;
            font-weight: 500;
        }

        button {
            color: #3E4047;
            background: #fff;
            border: 1px solid #fff;
        }
    }

    .date,
    .time {
        span {
            color: #fff;
        }
    }
}

.publishedCont {
    border-radius: 8px;
    background: #F0FDFA;
    padding: 20px;
    border: 1px solid #F0FDFA;
    margin-bottom: 20px;

    .nmTitle {
        h2 {
            color: #5E646E;
            font-weight: 600;
        }

        p {
            color: #9DA3AB;
        }
    }

    .flxCd {
        p {
            color: #787F89;
            font-weight: 500;
        }

        button {
            color: #FFFFFF;
            background: #2DD4BF;
            border: 1px solid #2DD4BF;
        }
    }

    .date,
    .time {
        span {
            color: #787F89;
        }
    }
}

.flxTopCd {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .dateTimeCd {
        display: flex;
        flex-wrap: wrap;

        .date,
        .time {
            display: flex;

            span {
                margin-left: 2px;
                font-weight: 500;
            }
        }

        .date {
            margin-right: 10px;
        }
    }
}

.flxCd {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    p {
        margin-bottom: 0px;
    }

    button {
        display: flex;
        align-items: center;
        padding: 8px 25px;
        border-radius: 8px;

        span {
            margin-left: 6px;
            font-weight: 600;
        }
    }
}

@media (max-width: 768px) {
    .draftCont, .publishedCont, .unpublishedCont, .activeLecCont {
        padding: 10px;
        h2 {
            font-size: 17px;
        }
        p {
            font-size: 13px;
        }
    }
    .flxTopCd {
        .dateTimeCd {
            flex-direction: column;
            align-items: flex-end;
            font-size: 13px;
            svg {
                height: 15px;
            }
            .date {
                margin-bottom: 10px;
                margin-right: 0px;
                align-items: center;
            }
            .time {
                align-items: center;
            }
        }
    }
    .flxCd {
        button {
            font-size: 12px;
            padding: 6px 10px;
            svg {
                height: 18px;
            }
            span {
                margin-left: 3px;
                margin-top: 2px;
            }
        }
    }
}