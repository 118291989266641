.padSkLd {
    padding: 50px;
}
.lectureDtCont {
    width: 100%;
    
  
    .topDt {
      display: flex;
      align-items: center;
      justify-content: space-between;
  
      p {
        margin-bottom: 0px;
      }
    }
  
    .endTp {
      display: flex;
      align-items: center;
  
      p {
        margin-bottom: 0px;
        margin-left: 40px;
      }
  
      p.id {
        color: #2563eb;
        font-weight: 600;
        font-size: 16px;
      }
  
      p.dt {
        span.cr {
          color: #787f89;
          font-weight: 600;
          margin-right: 5px;
        }
  
        span.crdt {
          color: #3e4047;
          font-weight: 500;
          font-size: 16px;
        }
      }
    }
  
    .goback {
      display: flex;
      align-items: center;
      cursor: pointer;
  
      h2 {
        margin: 0px 8px;
        color: #34363b;
        font-weight: 600;
      }
      p.draft {
        color: #4a4e57;
        background: #e1e3e6;
        border-radius: 100px;
        padding: 4px 16px;
        font-weight: 600;
      }

      p.published {
        color: #ffffff;
        background: #0d9488;
        border-radius: 100px;
        padding: 4px 16px;
        font-weight: 600;
      }

      p.unpublished {
        color: #ffffff;
        background: #ef4444;
        border-radius: 100px;
        padding: 4px 16px;
        font-weight: 600;
      }
    }
  
    .flxLcd {
      display: flex;
      margin-top: 40px;
      justify-content: space-between;
      .formField {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
    
        label {
          color: #787f89;
          font-weight: 700;
          font-size: 12px;
          line-height: 15px;
          margin: 0px 0px 5px 5px;
        }
        select,
        input, textarea {
          background: #F6F6F7;
          padding: 12px;
          border: 1px solid #C3C7CC;
          border-radius: 8px;
          color: #5e646e;
          outline: none;
        }
        input::placeholder, textarea::placeholder {
            color: #9DA3AB;
        }
        .flx {
            display: flex;
            justify-content: space-between;
            input {
                padding: 0px;
                border: 0px;
            }

            .ant-picker {
                width: 49%;
                padding: 14px 15px;
                background: #F6F6F7;
                border: 1px solid #C3C7CC;
                border-radius: 8px;
                outline: 0;

                .ant-picker-input {
                    display: flex;
                    align-items: center;
                    flex-direction: row-reverse;

                    span {
                        margin: 0px 4px 0px 0px;

                        svg {
                            fill: #9DA3AB;
                            font-weight: bolder;
                        }
                    }
                }
            }
        }
      }
      .flxBtnzLd {
        display: flex;
        justify-content: space-between;
        margin-top: 40px;
        button {
            padding: 16px 35px;
            font-weight: 700;
            border-radius: 8px;
        }
        button.save {
            color: #2563eb;
            border: 2px solid #2563eb;
            background-color: #fff;

        }
        button.publish {
            background: #2563EB;
            border: 2px solid #2563eb;
            color: #fff;
        }
        button.unpublish {
            background: #DC2626;
            border: 2px solid #DC2626;
            color: #fff;
        }
      }

      .scdMain {
        .tpScMnL {
            background: #FFFFFF;
            border-radius: 8px;
            padding: 22px;
            h3 {
                color: #18191B;
                font-weight: 500;
                font-size: 20px;
            }
            p {
                color: #3E4047;
            }
            .ivLnk {
                margin-bottom: 20px;
                display: flex;
                justify-content: space-between;
                background: #F6F6F7;
                border: 1px solid #C3C7CC;
                border-radius: 8px;
                color: #5e646e;
                border-right: 0px;
                input {
                    background: transparent;
                    border: 0px;
                    width: 100%;
                    outline: 0;
                    padding-left: 15px;
                }
                button {
                    border-radius: 8px;
                    outline: 0px;
                    border: 2px solid #14B8A6;
                    background: #14B8A6;
                    color: #fff;
                    padding: 12px 25px;
                }
                button:disabled {
                    background: #787F89;
                    cursor: not-allowed;
                    border: 2px solid #787F89;
                }
            }
            .flexSM {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                button {
                    background-color: #fff;
                    border: 0px;
                    margin-right: 25px;
                }
            }
            button.classroom {
                width: 100%;
                padding: 15px;
                color: #2563EB;
                font-weight: 700;
                background: #fff;
                border: 2px solid #2563EB;
                border-radius: 8px;
                margin-bottom: 20px;
            }
        }
        .btScMn {
            background: #FFFFFF;
            border-radius: 8px;
            padding: 22px;
            h3 {
                font-size: 20px;
            }
        }
      }
    }
    .tagWrp {
        display: flex;
        align-items: center;
        background: #99F6E4;
        border-radius: 100px;
        margin-right: 8px;
        margin-bottom: 8px;
        padding: 3px 10px 3px 5px;
        flex-wrap: wrap;

        svg {
            cursor: pointer;
        }

        span {
            color: #0F766E;
            font-weight: 600;
            font-size: 13px;
            margin-left: 3px;
            word-break: break-all;
        }
    }

    .kwdCont {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 20px;
    }
}
  

@media (max-width: 768px) {
    .lectureDtCont {
        .topDt {
            flex-direction: column;
            align-items: flex-start;
            .endTp {
                width: 100%;
                justify-content: space-between;
                margin-top: 30px;
                p {
                    margin-left: 0px;
                    font-size: 13px;
                }
                p.dt {
                    span.crdt, span.cr  {
                        font-size: 13px;
                    }
                }

            }
        }
        .flxLcd {
            flex-direction: column;
            .formField {
                .flx {
                    flex-direction: column;
                    .ant-picker {
                        margin-bottom: 0px;
                        width: 100%;
                    }
                }
                .flx > .ant-picker:first-child {
                    margin-bottom: 20px;
                }
            }
            .flxBtnzLd {
                flex-direction: column;
                button {
                    margin-bottom: 20px;
                }
            }
            .scdMain {
                .tpScMnL {
                    padding: 15px;
                    .flexSM {
                        button {
                            margin: 0px 15px 10px 0px;
                        }
                    }
                }
            }
        }
    }
}