.attDashboard {
    width: 100%;
    .flexMain {
        padding: 0px;
        margin-top: 60px;
        .scdMain {
            .tpScMn {
                padding: 0px 20px;
                button.classroom:disabled {
                    width: 100%;
                    padding: 15px;
                    color: #787F89;
                    font-weight: 700;
                    background: #E1E3E6;
                    border: 1px solid #C3C7CC;
                    border-radius: 8px;
                    cursor: not-allowed;
                }
                button.classroom {
                    width: 100%;
                    padding: 15px;
                    color: #2563EB;
                    font-weight: 700;
                    background: #fff;
                    border: 2px solid #2563EB;
                    border-radius: 8px;
                }
            }
        }
        .fstMain {
            h1 {
                font-weight: 600;
                font-size: 24px;
                color: #34363B;
                margin-bottom: 40px;
            }
            label {
                color: #787F89;
                font-weight: 700;
                font-size: 12px;
            }
            h3 {
                color: #3E4047;
                font-weight: 600;
                font-size: 20px;
            }
            .flCol {
                display: flex;
                flex-direction: column;
                margin: 30px 0px;
                textarea {
                    border: 1px solid #C3C7CC;
                    border-radius: 8px;
                    outline: none;
                    padding: 8px 10px;
                }
            }
            .TIMDcNT {
                display: flex;
                margin-top: 5px;
                .date, .time {
                    margin-right: 30px;
                    display: flex;
                    align-items: center;
                    span {
                        color: #1D4ED8;
                        font-weight: 500;
                        font-size: 16px;
                        margin-left: 3px;
                    }
                }
            }
            .mgTp {
                margin: 30px 0px;
                .keywCont {
                    margin: 3px 0px;
                    span.keyW {
                        background: #99F6E4;
                        border-radius: 100px;
                        font-weight: 600;
                        font-size: 12px;
                        color: #0F766E;
                        padding: 5px 15px;
                        margin-right: 6px;
                    }
                }
            }
            p.faci {
                color: #3E4047;
                font-weight: 600;
                font-size: 18px;
            }
            button.join {
                width: 100%;
                padding: 15px;
                color: #2563EB;
                font-weight: 700;
                background: #fff;
                border: 2px solid #2563EB;
                border-radius: 8px;
                margin-top: 30px;
            }
            button.leave {
                width: 100%;
                padding: 15px;
                color: #DC2626;
                font-weight: 700;
                background: #fff;
                border: 2px solid #DC2626;
                border-radius: 8px;
                margin-top: 30px;
            }
        }
    }
    .centerAtt {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 120px;
        p {
            color: #4A4E57;
            font-weight: 600;
            font-size: 24px;
            span {
                color: #14B8A6;
            }
        }
        .whiteAtt {
            background: #FFFFFF;
            border-radius: 8px;
            text-align: center;
            width: 70%;
            padding: 30px;
            .lott {
                div {
                    height: 300px;
                }
                p {
                    font-size: 20px;
                }
            }
        }
    }
}

@media (max-width:768px){
    .attDashboard {
        .flexMain {
            margin-top: 30px;
            .scdMain {
                .tpScMn {
                    padding: 0px;
                }
            }
        }
        .centerAtt {
            margin-top: 60px;
            p {
                font-size: 18px;
            }
            .whiteAtt {
                padding: 0px;
                width: 100%;
                .lott {
                    div {
                        height: 250px;
                    }
                }
            }
        }
    }
}