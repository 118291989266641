.fullCard {
    background: #FFFFFF;
    border-bottom: 1px solid #E1E3E6;
    padding: 20px;
    margin-bottom: 20px;
    .innerCard {
        background: #FFFFFF;
        border: 0.5px solid #F6F6F7;
        box-shadow: 0px 8px 16px rgba(74, 78, 87, 0.04);
        border-radius: 8px;
        padding: 20px;
        .flxTp {
            display: flex;
            width: 100%;
            img {
                height: 40px;
                width: 40px;
                border-radius: 100%;
                margin-right: 15px;
                background-position: center;
                object-fit: cover;
            }
            .rghtSd {
                width: 100%;
                p {
                    color: #5E646E;
                }
                .flxTimeSt {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    .post {
                        span.author {
                            color: #9DA3AB;
                            font-weight: 600;
                            span {
                                color: #4A4E57;
                            }
                        }
                    }
                    .time {
                        span.tme {
                            color: #2563EB;
                            font-weight: 400;
                            display: flex;
                            align-items: center;
                            span.ct {
                                display: flex;
                                align-items: center;
                                margin-left: 5px;
                                span {
                                    margin-left: 2px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .flexWActs {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
        button.upvote {
            background-color: #fff;
            border: 0px;
            display: flex;
            align-items: center;
            .facUpvts {
                border: 1px solid #14B8A6;
                height: 50px;
                width: 50px;
                border-radius: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding-top: 4px;
                p.numVots {
                    margin-bottom: 0px;
                    color: #14B8A6;
                }
            }
            span.btnName {
                font-weight: 600; 
                margin-left: 5px;
                font-size: 16px;
                span.upvoted {
                    color: #14B8A6;
                }
                span.upvote {
                    color: #9DA3AB;
                }
            }
        }
        .facBtns {
            button.answ {
                display: flex;
                align-items: center;
                background-color: #fff;
                padding: 10px 20px;
                border: 1px solid #2563EB;
                color: #2563EB;
                border-radius: 8px;
                font-weight: 600;
                span {
                    margin-left: 5px;
                }
            }
            button.bluAns {
                background: #2563EB;
                border-radius: 8px;
                color: #fff;
                padding: 10px 20px;
                border: 1px solid #2563EB;
            }
        }
    }
}

.rankCd {
    background: #FFFFFF;
    border: 0.5px solid #C3C7CC;
    box-shadow: 0px 8px 16px rgba(74, 78, 87, 0.08);
    border-radius: 8px;
    margin-bottom: 25px;
    padding: 16px;
    position: relative;
    .flxTimeSt {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .post {
            img {
                height: 40px;
                width: 40px;
                border-radius: 100%;
            }
            span.author {
                color: #9DA3AB;
                font-weight: 600;
                margin-left: 5px;
                span {
                    color: #4A4E57;
                }
            }
        }
        .time {
            span.tme {
                color: #2563EB;
                font-weight: 400;
                display: flex;
                align-items: center;
                span.ct {
                    display: flex;
                    align-items: center;
                    margin-left: 5px;
                    span {
                        margin-left: 2px;
                    }
                }
            }
        }
    }
    p.tag {
        background: #0D9488;
        border-radius: 100px;
        color: #FFFFFF;
        font-weight: 500;
        position: absolute;
        padding: 4px 15px;
        font-size: 11px;
        top: -13px;
        right: 15px;
    }
    .border {
        width: 4px;
        height: 40px;
        background: #2563EB;
        border-radius: 0px 100px 100px 0px;
        position: absolute;
        top: 36px;
        left: 0px;
    }
}

@media (max-width:768px) {
    .fullCard {
        padding: 10px;
        .innerCard {
            padding: 10px;
            .flxTp {
                .rghtSd {
                    .flxTimeSt {
                        flex-direction: column;
                        .post {
                            margin-bottom: 3px;
                        }
                    }
                }
            }
        }
        .flexWActs {
            margin-top: 10px;
            .facBtns {
                button.answ {
                    padding: 8px 10px;
                }
            }
        }
    }
    .leftClass {
        .rkCont {
            margin-top: 0px;
            .rankCd {
                padding: 14px;
                .flxTimeSt {
                    flex-direction: column;
                    align-items: flex-start;
                }
            }
        }
    }
}